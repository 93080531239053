<template>
    <div>
      <va-modal
        v-model="showLog"
        size="large"
        :hide-default-actions="true"
        :title="'Reporting Log'"
      >
        <div class="row">
          <VueJsonPretty :data="selectedLog" />
        </div>
      </va-modal>
      <va-card
        title="Reporting Logs"
      >
        <template slot="actions">
          <div class="row">
            <va-button
              icon="fa fa-refresh"
              @click="getLogs();"
            />
          </div>
        </template>
        <div class="row align--center">
          <div class="flex xs12 md6">
            <va-input
              :value="term"
              :placeholder="'Search by process, type, status (true/false)...'"
              @input="search"
              removable
            >
              <va-icon
                name="fa fa-search"
                slot="prepend"
              />
            </va-input>
          </div>
          <div class="flex xs12 md3 offset--md3">
            <va-select
              v-model="perPage"
              :label="$t('tables.perPage')"
              :options="perPageOptions"
              no-clear
            />
          </div>
        </div>
        <va-data-table
          :fields="fields"
          :data="filteredData"
          :per-page="parseInt(perPage)"       
          clickable
          hoverable
          :loading="loading"
        >

          <template 
            slot="relevantData"
            slot-scope="props"
           >
            <a href="#"  @click="showLogModal(props.rowData, 'params')">Params</a> /
            <a href="#"  @click="showLogModal(props.rowData, 'response')">Response </a>
          </template>
          <template
            slot="status"
            slot-scope="props"
          >
            <span v-if="validateStatus(props.rowData)">
              <i class="fa fa-check" />
            </span>
            <span v-else>
               <i class="fa fa-times" style="color: red;"/>
            </span>
          </template>
        </va-data-table>
      </va-card>
    </div>
  </template>
  <script>
  import firebase from 'firebase';
  import moment from 'moment';
  import {debounce} from 'lodash';
  import VueJsonPretty from 'vue-json-pretty';
  import 'vue-json-pretty/lib/styles.css';
  
  export default {
    components: {
      VueJsonPretty,
    },
    data: function() {
      return {
        loading: true,
        log: [],
        showLog: false,
        selectedLog: {},
        perPage: '10',
        term: '',
        perPageOptions: ['10', '50', '100', '250'],
      };
    },
    created() {
      this.getLogs();
    },
    methods: {
      validateStatus(row){
        //In the future, we can add more conditions here to determine if the log has been successful
        return !row.error
      },
      convertXmlToJson(xmlString) {
        //remove "inXML=" from the string
        xmlString = xmlString.replace('inXML=', '')
        const jsonData = {};
            for (const result of xmlString.matchAll(/(?:<(\w*)(?:\s[^>]*)*>)((?:(?!<\1).)*)(?:<\/\1>)|<(\w*)(?:\s*)*\/>/gm)) {
                const key = result[1] || result[3];
                const value = result[2] && this.convertXmlToJson(result[2]); //recusrion
                jsonData[key] = ((value && Object.keys(value).length) ? value : result[2]) || null;
            }
            return jsonData;
      },
      showLogModal(data, item){
        //check if data[item] starts with inXML=
        let result = JSON.stringify(data[item]).startsWith('"InXML=') ? this.convertXmlToJson(decodeURIComponent(data[item])) : data[item]
        //check if result is null 
        this.selectedLog = !result ? "Oops, no data available!": result;
        this.showLog = true 
      },
      getLogs() {
        this.log = [];
        this.loading = true;
        firebase.firestore().collection('logs').orderBy('date', 'desc').get().then((snapshot)=>{
          snapshot.docs.forEach((log)=>{
            const data = log.data();
            data.id = log.id;
            data.date = this.parseDate(data.date);
            data.parsedSubject = 'Tests';
            this.log.push(data);
          });
          this.loading = false;
        });
      },
      parseDate(date) {
        try {
          return moment(new Date(date.seconds*1000)).format('MM/D/YYYY, h:mm:ss a');
        } catch (e) {
          return 'Error parsing date... Probably in transit';
        }
      },
      search: debounce(function(term) {
        /* eslint-disable */
        this.term = term;
      }, 100),
    },
    computed: {
      filteredData() {
        if ((!this.term || this.term.length < 1) ) {
          return this.log;
        }
  
        return this.log.filter((item) => {
          let returnLog = false;
    
          if (item.id.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
            returnLog = true;
          }
          if (item.process.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
            returnLog = true;
          }
          if (item.type.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
            returnLog = true;
          }
          if (item.date.toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
            returnLog = true;
          }
          
          if (item.error.toString().toLowerCase().indexOf(this.term.toLowerCase()) !== -1) {
            returnLog = true;
          }
  
          return returnLog;
        });
      },
      fields() {
        return [{
          name: 'id',
          title: 'ID',
        },
        {
          name: 'process',
          title: 'Process',
          sortField:'process'
        },
        {
            name:'type',
            title:'Type',
            sortField:'type'
        },
        {
            name:'__slot:relevantData',
            title:'Params/Response'
        },
        {
            name:'__slot:status',
            sortField:'error',
            title:'Status'
        },
        {
          name: 'date',
          title: 'Date',
          sortField:'date'
        }];
      },
    },
  };
  </script>
  